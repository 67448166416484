import { debounce } from 'lodash';
import React, { createContext, memo, useContext, useState } from 'react';
import ShortUniqueId from 'short-unique-id';
import firebase from 'gatsby-plugin-firebase';
import UserContext from './UserContext';
import initialState from '../data/initialState.json';
import initialState2 from '../data/initialState2.json';

const DEBOUNCE_WAIT_TIME = 4000;

const defaultState = {
  isUpdating: false,
  createResume: async () => {},
  duplicateResume: async () => {},
  deleteResume: () => {},
  getResume: async () => {},
  getResumes: async () => {},
  updateResume: async () => {},
  debouncedUpdateResume: async () => {},
  createCover: async () => {},
  duplicateCover: async () => {},
  deleteCover: () => {},
  getCover: async () => {},
  getCovers: async () => {},
  updateCover: async () => {},
  debouncedUpdateCover: async () => {},
};

const DatabaseContext = createContext(defaultState);

const DatabaseProvider = ({ children }) => {
  const dictionary = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('');
  const uuid = new ShortUniqueId({ dictionary });

  const [isUpdating, setUpdating] = useState(false);
  const { user } = useContext(UserContext);

  const getResume = async (id) => {
    try {
      const snapshot = await firebase
        .database()
        .ref(`resumes/${id}`)
        .once('value');
      return snapshot.val();
    } catch (error) {
      return null;
    }
  };

  const getCover = async (id) => {
    try {
      const snapshot = await firebase
        .database()
        .ref(`covers/${id}`)
        .once('value');
      return snapshot.val();
    } catch (error) {
      return null;
    }
  };

  const createResume = async ({ name }) => {
    const id = uuid();
    const createdAt = firebase.database.ServerValue.TIMESTAMP;

    let firstName;
    let lastName;

    if (!user.isAnonymous) {
      [firstName, lastName] = user.displayName.split(' ');
    }

    const resume = {
      ...initialState,
      id,
      name,
      user: user.uid,
      profile: {
        ...initialState.profile,
        firstName: firstName || '',
        lastName: lastName || '',
      },
      createdAt,
      updatedAt: createdAt,
    };

    firebase.database().ref(`resumes/${id}`).set(resume);

    return id;
  };

  const createCover = async ({ name }) => {
    const id = uuid();
    const createdAt = firebase.database.ServerValue.TIMESTAMP;

    let firstName;
    let lastName;

    if (!user.isAnonymous) {
      [firstName, lastName] = user.displayName.split(' ');
    }

    const cover = {
      ...initialState2,
      id,
      name,
      user: user.uid,
      profile: {
        ...initialState2.personaldetails,
        firstName: firstName || '',
        lastName: lastName || '',
      },
      createdAt,
      updatedAt: createdAt,
    };

    firebase.database().ref(`covers/${id}`).set(cover);

    return id;
  };

  const duplicateResume = async (originalResume) => {
    const id = uuid();
    const createdAt = firebase.database.ServerValue.TIMESTAMP;

    const resume = {
      ...originalResume,
      id,
      name: `${originalResume.name} Copy`,
      createdAt,
      updatedAt: createdAt,
    };

    firebase.database().ref(`resumes/${id}`).set(resume);
  };

  const duplicateCover = async (originalCover) => {
    const id = uuid();
    const createdAt = firebase.database.ServerValue.TIMESTAMP;

    const cover = {
      ...originalCover,
      id,
      name: `${originalCover.name} Copy`,
      createdAt,
      updatedAt: createdAt,
    };

    firebase.database().ref(`covers/${id}`).set(cover);
  };

  const updateResume = async (resume) => {
    setUpdating(true);

    await firebase
      .database()
      .ref(`resumes/${resume.id}`)
      .update({
        ...resume,
        updatedAt: firebase.database.ServerValue.TIMESTAMP,
      });

    setUpdating(false);
  };

  const updateCover = async (cover) => {
    setUpdating(true);

    await firebase
      .database()
      .ref(`covers/${cover.id}`)
      .update({
        ...cover,
        updatedAt: firebase.database.ServerValue.TIMESTAMP,
      });

    setUpdating(false);
  };

  const debouncedUpdateResume = debounce(updateResume, DEBOUNCE_WAIT_TIME);
  const debouncedUpdateCover = debounce(updateCover, DEBOUNCE_WAIT_TIME);

  const deleteResume = async (id) => {
    await firebase.database().ref(`/resumes/${id}`).remove();
  };

  const deleteCover = async (id) => {
    await firebase.database().ref(`/covers/${id}`).remove();
  };

  return (
    <DatabaseContext.Provider
      value={{
        isUpdating,
        getResume,
        createResume,
        duplicateResume,
        updateResume,
        deleteResume,
        debouncedUpdateResume,
        getCover,
        createCover,
        duplicateCover,
        updateCover,
        deleteCover,
        debouncedUpdateCover,
      }}
    >
      {children}
    </DatabaseContext.Provider>
  );
};

export default DatabaseContext;

const memoizedProvider = memo(DatabaseProvider);

export {
  memoizedProvider as DatabaseProvider,
  DEBOUNCE_WAIT_TIME as DebounceWaitTime,
};
